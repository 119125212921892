.responsive-image {
  position: relative;
  max-width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.responsive-image__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
